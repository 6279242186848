import { FC } from 'react'
import { MainHeadingProps } from './MainHeading.d'

const MainHeading: FC<MainHeadingProps> = ({ title, subtitle }) => {
  return (
    <>
      <h1 className="uppercase text-3xl sm:text-4xl">{title}</h1>
      <h2 className="uppercase text-sm sm:text-lg mb-5">{subtitle}</h2>
    </>
  )
}

export default MainHeading
