import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { SeoProps } from './Seo.d'

const Seo: FC<SeoProps> = ({ title, description, siteUrl, lang }) => {
  return (
    <Helmet title={title} htmlAttributes={{ lang }}>
      {siteUrl && <meta property="og:url" content={siteUrl} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
    </Helmet>
  )
}

export default Seo
